<template>
  <Layout>
    <PageHeader :title="$t(title)" :items="items"/>
    <div class="row">
      <div class="col-lg-6">
        <div class="card">
          <div class="card-header">
            <h3>{{ user.first_name + " " + user.last_name }}</h3>
          </div>
          <div class="card-body">
            <form @submit.prevent="tryUpdateUser">
              <!-- Profile Image Display and Dropzone for Upload -->
              <div class="form-group">
                <label>{{ $t('general.profile_image') }}</label>
                <div class="row">
                  <div class="col-6">
                    <vue-dropzone ref="myVueDropzone" id="dropzone" class="profile-dropzone"
                                  @vdropzone-success="uploadSuccess"
                                  :options="dropzoneOptions"></vue-dropzone>
                  </div>
                </div>
              </div>

              <!-- Two fields in a row for large screens -->
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="first_name">{{ $t('general.first_name') }}</label>
                    <input
                        type="text"
                        id="first_name"
                        v-model="user.first_name"
                        class="form-control"
                        required
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="last_name">{{ $t('general.last_name') }}</label>
                    <input
                        type="text"
                        id="last_name"
                        v-model="user.last_name"
                        class="form-control"
                        required
                    />
                  </div>
                </div>
              </div>

              <!-- Another row of two fields -->
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="gender">{{ $t('general.gender') }}</label>
                    <select v-model="user.gender" id="gender" class="form-control" required>
                      <option value="1">{{ $t('general.male') }}</option>
                      <option value="2">{{ $t('general.female') }}</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="birth_year">{{ $t('general.birth_year') }}</label>
                    <input
                        type="number"
                        id="birth_year"
                        v-model="user.birth_year"
                        class="form-control"
                        required
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="height_cm">{{ $t('general.height_cm') }}</label>
                    <input
                        type="number"
                        id="height_cm"
                        v-model="user.height_cm"
                        class="form-control"
                        step="0.5"
                        required
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="height_cm">{{ $t('general.leg_length_cm') }}</label>
                    <input
                        type="number"
                        id="leg_length_cm"
                        v-model="user.leg_length_cm"
                        step="0.1"
                        class="form-control"
                        required
                    />
                  </div>

                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="weight_kg">{{ $t('general.weight_kg') }}</label>
                    <input
                        type="number"
                        id="weight_kg"
                        v-model="user.weight_kg"
                        class="form-control"
                        step="0.1"
                        required
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="weight_kg">{{ $t('general.weight_pct') }}</label>
                    <input
                        type="number"
                        id="weight_pct"
                        v-model="user.weight_pct"
                        class="form-control"
                        required
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="country">{{ $t('general.country') }}</label>
                    <select v-model="user.country" id="country" class="form-control" required>
                      <option value="AU">Australia</option>
                      <option value="CA">Canada</option>
                      <option value="CY">Cyprus</option>
                      <option value="FR">France</option>
                      <option value="DE">Germany</option>
                      <option value="IL">Israel</option>
                      <option value="IT">Italy</option>
                      <option value="JP">Japan</option>
                      <option value="MX">Mexico</option>
                      <option value="KR">S. Korea</option>
                      <option value="GB">United Kingdom</option>
                      <option value="US">USA</option>
                    </select>
                  </div>

                </div>
              </div>

              <div class="d-flex justify-content-between">
                <button type="button" class="btn btn-secondary" @click="goBack">
                  {{ $t('general.cancel') }}
                </button>
                <button type="submit" class="btn btn-primary">
                  {{ $t('general.save') }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '../../layouts/main'
import PageHeader from '@/components/page-header'
import {userMethods} from '@/state/helpers'
import {getApi} from '@/api'
import vue2Dropzone from 'vue2-dropzone'

export default {
  components: {
    Layout,
    PageHeader,
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      user: {
        first_name: '',
        last_name: '',
        gender: 1,
        birth_year: '',
        height_cm: '',
        weight_kg: '',
        leg_length_cm: '',
        weight_pct: '',
        country: '',
        image: ''
      },
      title: this.$t('pages.accountEdit.title'),
      items: [
        {text: this.$t('general.account'), href: '/'},
        {text: this.$t('pages.accountEdit.title'), active: true}
      ],
      dropzoneOptions: {
        url: 'https://api.vrsteps.co/upload.php',
        maxFilesize: 0.3,  // Max file size in MB
        resizeQuality: 0.75,
        acceptedFiles: 'image/*',  // Accept only images
        thumbnailWidth: 100,  // Thumbnail dimensions
        thumbnailHeight: 100,
        maxFiles: 1,  // Allow only one file
        headers: {
          'User': this.$store.state.user.id,
          'Token': this.$store.state.user.access_token
        }
      }
    }
  },
  methods: {
    ...userMethods,
    ...getApi(),

    // Fetch existing user data based on the user ID from the route
    fetchUserData() {
      const adminId = this.$store.state.user.id;
      const accessToken = this.$store.state.user.access_token;
      const userId = this.$route.params.id;

      this.getUsers(adminId, accessToken, userId)
          .then(response => response.json())  // Convert response to JSON
          .then(data => {
            console.log(data);
            if (data.status) {
              this.user = data.user;  // Access the correct data
              let file = {
                name: this.user.image.split('/').pop(),  // Get the file name from the image URL
                size: 100,  // Size (required, but it can be a mock value)
                type: "image/jpeg"  // Assuming the image is a jpeg
              };
              this.$refs.myVueDropzone.manuallyAddFile(file, this.accessImage(this.user.image));
              this.$refs.myVueDropzone.dropzone.emit("thumbnail", file, this.accessImage(this.user.image));
            } else {
              console.error("Error: Invalid response or status is false");
            }
          })
          .catch(error => {
            console.error('Error fetching user data:', error);
          });
    },

    // Save user changes
    tryUpdateUser() {
      let userId = this.$store.state.user.id;
      let token = this.$store.state.user.access_token;

      this.updateUser(userId, token, this.user).then(response => {
        return response.json(); // Parse the JSON response
      }).then(data => {
        if (data.status) {
          // Success: Do something, like refreshing the page or redirecting
          window.location.reload(); // This will reload the page
        } else {
          alert(this.$t('general.error'));
        }
      }).catch(error => {
        console.error('Error updating user:', error);
        alert(this.$t('general.error'));
      });
    },


    uploadSuccess(file, response) {
      this.user.image = response.fileUrl
    },

    accessImage(url) {
      return url + "?unity=true";
    },

    // Cancel and go back to the previous page
    goBack() {
      this.$router.go(-1)
    }
  },
  mounted() {
    this.fetchUserData()
  }
}
</script>
